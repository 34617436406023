<template>
    <main-template>
        <template v-slot:headerLottieDark>
            <dotlottie-player :src="getDarkLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:headerLottieLight>
            <dotlottie-player :src="getLightLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal" ></dotlottie-player>
        </template>
        <template v-slot:header>Hub</template>
        <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
                            <!--                            <Hr-navigation :links="slotProps.links"/>-->
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-white-100 p-2 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                <loading v-if="!response"></loading>
                                <template v-else>
                                    <div class="overflow-auto h-full w-full p-2">
                                        <blank-header>
                                            <template v-slot:title>
                                                Slip Send
                                            </template>
                                        </blank-header>
                                        <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                                            <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                                                <!-- Description list-->
                                                <section>
                                                    <div class="bg-white shadow sm:rounded-lg">
                                                        <div class="px-4 sm:px-6 md:px-8 pt-5">
                                                            Templates
                                                        </div>
                                                        <pay-n-send-template-paginated-list :response="response"></pay-n-send-template-paginated-list>

                                                    </div>
                                                </section>

                                            </div>
                                            <section v-if="response.body.actions.filter(action =>action.name !== 'update-material').count() !== 0" class="lg:col-start-3 lg:col-span-1">
                                                <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">

                                                    <pending-pay-n-send-actions :response="response" :remainingActions="response.body.actions.filter(action =>action.name !== 'update-material')"></pending-pay-n-send-actions>
                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import Actions from "@/v2/components/Actions";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import Button from "@/v2/buttons/Button";
import DayPayRequestsPaginatedList from "@/v2/paginatedLists/DayPayRequestsPaginatedList.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot";
import PageHeader from "@/v2/components/Header";
import Loading from "@/components/Loading";
import MainTemplate from "@/v3/templates/MainTemplate.vue";
import PayNSendTemplatePaginatedList from "@/v2/paginatedLists/PayNSendTemplatePaginatedList.vue";
import PendingPayNSendActions from "@/v2/components/PendingPayNSendActions.vue";
export default {
    name: "Material",
    data() {
        return {
            displayMaterialDetails: false
        }
    },
    components: {
        DayPayRequestsPaginatedList, BlankHeader,
        PendingPayNSendActions,
        PayNSendTemplatePaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        Actions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_light"
        },
    }
}
</script>

<style scoped>

</style>
